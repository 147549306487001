import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import cardiomood_wide_logo from './cardiomood_logo_wide.svg'
import axios from "axios";

import toast, {Toaster} from 'react-hot-toast';

function getName(user){
    if (user == undefined){
        return;
    }
    let {first_name, last_name, email} = user;
    let gs = s => (s == undefined ? '' : s);
    return `${gs(first_name)} ${gs(last_name)}`;
}

export default function AuthPanel(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [users_token, setUsers_token] = useState(props.users_token);
    const [health_token, setHealth_token] = useState(props.health_token);
    const [user, setUser] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    let uName = getName(user);

    return (
        <Wrapper>

            {users_token != undefined ? null :
                <FirstStepPlaceholder>
                    <TopLogoPlaceholder>
                        <Logo src={cardiomood_wide_logo}/>
                    </TopLogoPlaceholder>

                    <Row>
                        <Label>
                            Login
                        </Label>
                        <Input value={email} placeholder={'Your email or code'}
                               onChange={evt => {
                                   setEmail(evt.target.value);
                               }}/>
                    </Row>

                    <Row>
                        <Label>
                            Password
                        </Label>
                        <Input type={password}
                               placeholder={'Your password'}
                               value={password}
                               onChange={evt => {
                                   setPassword(evt.target.value);
                               }}/>
                    </Row>

                    <ButtonPlaceholder>
                        <Button onClick={async () => {
                            if (email == undefined || password == undefined || email.indexOf('@') == -1 || password.length < 4) {
                                toast('please provide email and password')
                                return;
                            }
                            setLoading(true);
                            try {
                                let pld = (await axios.post(`https://api.users.cloud.mmt.ch/login`, {
                                    email: email,
                                    password: password
                                })).data;
                                let pld2 = (await axios.post(`https://api.health.cloud.mmt.ch/login`, {
                                    user_api_token: pld.token
                                })).data;
                                setUser(pld.user);
                                setUsers_token(pld.token);
                                setHealth_token(pld2.token);
                            } catch (exc) {
                                toast('can not sign in');
                            }
                            setLoading(false);
                        }}>
                            {loading == true ? 'Signing in...' : 'Sign in'}
                        </Button>
                    </ButtonPlaceholder>

                </FirstStepPlaceholder>
            }

            {users_token == undefined ? null :
                <div>
                    <h2>
                        {`${uName}`}
                    </h2>
                    <p style={{fontSize: 12}} >
                        {user?.email}
                    </p>
                    <h3>
                        Authorization request
                    </h3>
                    <p>
                        Service is requesting permission to access your account.
                    </p>
                    <p>
                        This application will be able to:
                    </p>
                    <ul>
                        <li>
                            Get health information
                        </li>
                    </ul>

                    <AgreeCancelPlaceholder>
                        <Button onClick={() => {

                        }}>
                            Authorize
                        </Button>
                    </AgreeCancelPlaceholder>

                    <CancelPlaceholder>
                        <CancelSpan onClick={() => {
                            setEmail('');
                            setPassword('');
                            setUsers_token(undefined);
                            setHealth_token(undefined);
                            setUser(undefined);
                        }}>
                            Cancel
                        </CancelSpan>
                    </CancelPlaceholder>

                    <div style={{fontSize: 10}} >
                        <div style={{marginBottom: 20}} >
                            {`users token = ${users_token}`}
                        </div>
                        <div>
                            {`health token = ${health_token}`}
                        </div>

                    </div>

                </div>
            }

        </Wrapper>
    );
}

const AgreeCancelPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CancelPlaceholder = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CancelSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const FirstStepPlaceholder = styled.div`

`;

const TopLogoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
`;

const Logo = styled.img`
  height: 60px;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.8;
  color: white;
  background: #E62D3A;
  border-radius: 6px;
  font-size: 16px;
  height: 40px;

  :hover {
    opacity: 1;
  }
`;

const ButtonPlaceholder = styled.div`

`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  padding-left: 10px;
  outline: none;
  border: 1px solid whitesmoke;

  :focus {
    border-color: blue;
  }
`;

const Wrapper = styled.div`
  padding-top: 20vh;
  box-sizing: border-box;
  width: 420px;
  margin: 0 auto;
  @media (max-width: 420px) {
    width: 100%;
  }
`;

const Row = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;