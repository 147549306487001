import logo from './logo.svg';
import './App.css';
import AuthPanel from "./components/AuthPanel";
import toast, { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
      <AuthPanel />
      <Toaster />
    </div>
  );
}

export default App;
